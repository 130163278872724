<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            circle
            :src="companyInfo.lo"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <!-- <b-media-body class="mt-75 ml-75">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
        />
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body> -->
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Company Name"
            label-for="account-company-name"
          >
            <b-form-input
              readonly
              :placeholder="companyInfo.nm"
              name="company-name"
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="secondary"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Number of unit allowed
              <div class="font-small-3">
                You subscribe to manage 50 units 15 unit has been used
              </div>
            </h4>

          </b-alert>
          <b-alert
            show
            variant="info"
            class="mb-50 mt-1"
          >
            <div class="alert-heading font-small-3">
              Use the App to update your company information and manage your users.
            </div>

          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="6">

          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Save Changes
          </b-button> -->
        </b-col>

        <b-col
          cols="6"
          class="d-flex align-items-between align-items-end flex-column"
        >

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="mt-2 mr-1"
            @click="logoutCompany"
          >
            Logout From company
          </b-button>
        </b-col>

      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BMedia, BMediaAside, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BMedia,
    BMediaAside,
    BLink,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState({
      companyInfo: state => state.auth.companyInfo,
    }),
  },
  methods: {
    logoutCompany() {
      this.$store.dispatch('auth/logoutCompany')
    },
  },
}
</script>

<style scoped>

</style>
